import { Component, computed, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DividerModule } from 'primeng/divider';
import { ButtonModule } from 'primeng/button';
import { AvatarModule } from 'primeng/avatar';
import { TooltipModule } from 'primeng/tooltip';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';
import { filter, map } from 'rxjs';
import { CustomerService } from '../../../../../../apps/suite/src/app/shared/access';

@Component({
  selector: 'lib-rail',
  standalone: true,
  imports: [CommonModule, DividerModule, ButtonModule, AvatarModule, TooltipModule, RouterLink],
  templateUrl: './rail.component.html',
  styleUrl: './rail.component.scss',
})
export class RailComponent {
  router = inject(Router);
  customerService = inject(CustomerService);

  currentRoute = toSignal(
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((navigationEnd) => navigationEnd.url),
    ),
  );

  currentCustomer = toSignal(this.customerService.selectedCustomer$);

  selectCustomerTooltip = computed(() => {
    const currentCustomer = this.currentCustomer();
    if (!currentCustomer) {
      return 'Unknown customer';
    }

    return `${currentCustomer.customerID} - ${currentCustomer.name}`;
  });
}
